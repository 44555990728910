.topnav{
  background:$white;
  height: 65px;
  padding:10px 10px 10px 0;
  border-bottom:solid 1px $light-grey;
  .welcome{
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    line-height: 40px;
  }
  .dropdown{
    display: inline-block;
  }
  color:$black;
  .btn.btn-clear{
    color:$black;
    color: #3a3a3a;
    font-size: 18px !important;
    line-height: 40px;
  }
}
.nav-tabs{
  .nav-item{
    .nav-link{
      &.active{
        background:$secondary;
        color:$white;
        &:hover{
          background:$secondary;
          color:$white;
        }
      }
      &:hover{
        background:$primaryAction;
        color:$white;
      }
    }
  }
}
.tab-content{
  padding:20px;
}
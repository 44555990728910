.modal-content{
    border:none;
    .modal-header{
        background:$primary;
        .modal-title{
            color:$white!important;   
        }
        .close{
            color:$white!important;  
            border:none;
        }
    }
}

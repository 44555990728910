.canvasComponent{
    display: flex;
    flex:1;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    padding:10px;
    border-radius: 5px;
    background:$gradient;
    color:$white;
    border:solid 1px $dark-grey;
    cursor: pointer;
    margin-bottom:10px;
    &.sorting{
        border:solid 1px #fff;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        background:linear-gradient(90deg, $primary 0%, $primary 100%);
    }
    &.global{
        background:linear-gradient(90deg, $tertiary 0%, $secondary 100%);
    }
    .name{
        width:70%;
        display: flex;
        flex-direction:row;
        align-items:center;
        justify-content:flex-start;
    }
    .buttons{
        width:30%;
        display: flex;
        flex-direction:row;
        align-items:center;
        justify-content:flex-end;
        .edit{
            padding:5px;
            border-radius: 3px;
            background: $primary;
            color:$white;
            margin-left: 10px;
        }
        .delete{
            padding:5px;
            border-radius: 3px;
            background: $warning;
            color:$white;
        }
    }

}

.stats{
    .statTitle{
        display:block;
        text-align: center;
        font-size: 10pxpx;
        color:$primary
    }
    .stat{
        display:block;
        text-align: center;
        font-size: 40px;
        color:$primary
    }
}
.login_container{
    text-align: center;
    background:$primary;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height:100%;
    min-height: 100vh;
    width:100vw;
    padding:30px!important;
    h3{
      color:$white;
      font-size:3em
    }
    .card{
      p{
        color:$black;
        font-size:1em
      }
    }
    img{
      width:300px;
      margin-bottom:40px;
    }
    .card-body{
        padding:30px;
    }
}

.addshake {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }
  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  .initialLoading{
    margin-top:10%;
  }
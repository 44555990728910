
.border_right{
    border-right:1px solid $grey;
}
.centerCell{
    display:block;
    width:100%;
    text-align: center;
}
table{
    border:1px solid $lighter-grey;
    width:100%;
}
td{
    border:4px solid $white;
    text-align: center;
    padding:8px 8px!important;
    &.title{
        padding:2px 2px!important;
    }
    font-size:0.8em;
    background:#f2f2f2;
    vertical-align: middle!important;
}
.passed{
    td{
        background:$lighter-grey;
    }
}
.contact{
    text-align: center;
    td{
        padding:15px 5px!important;
        &.highlight{
            background:$primary!important;
            color:$white;
            border-right:1px solid $white;
        }
    }
}
thead{
    th{
        text-align: center;
    }
    &.primary{
        th{
            background:$primary!important;
            color:$white;
            padding:10px 5px!important;
            border:3px solid $white;
            text-align: center;
        }
    }
    &.grey{
        th{
            background:$grey!important;
            color:$black;
            padding:3px 5px!important;
            border:3px solid $white;
            text-align: center;
            font-size: 11px;
        }
    }
    &.lightGrey{
        th{
            background:$light-grey!important;
            color:$black;
            padding:15px 5px!important;
            border-right:1px solid $white;
            text-align: center;
        }
    }
}
.footer{
    background:$lighter-grey;
    td{
        border-right:1px solid $white;
    }
}
.-odd{
    background:$lighter-grey!important;
}


.sidebar {
    z-index: 100;
    /* Behind the navbar */
    padding: 0 0 0 0!important;
    /* Height of navbar */
    width: 100%;
    &:-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    &.sidebar-sticky {
        position: relative;
        top: 0;
        height: calc(100vh - 96px);
        overflow-x: hidden;
        overflow-y: auto;
        /* Scrollable contents if viewport is shorter than content. */
        height: 100vh;
      }
      
    &.sidebar-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        text-align: center;
        color: $light-grey;
      }
    .nav-link {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        color: $light-grey;
        font-size: 22px;
        span{
            font-size: 16px;
        }
        .icon {
            font-size: 28px;
            
        }
        .small{
            font-size: 12px;
            line-height: 40px;
        }
        .togglearrow{
            position: relative;
            top: -2px;
            left: 8px;
            font-size: 12px;
        }
        &.sublink{
            font-size: 12px;
        }
        &:hover {
            background:$secondary;
        }
        &.active {
            background:$secondary;
        }
    }
    .brand-item{
        padding:20px 0!important;
    }
    .sidebar-heading {
        font-size: 0.75rem;
        text-transform: uppercase;
      }
    .nav-item {
        padding:2px 0px;
        width: 100%;
        text-align: center;
        img{
            width:60%;
            padding:3%;
        }
        @media (min-width: 60em) {
            img{
            width:60%;
            }
        }
        @media (max-width: 35.5em) {
            img{
            width:60%;
            }
        }
    }
    @supports (position: -webkit-sticky) or (position: sticky) {
        &.sidebar-sticky {
            position: -webkit-sticky;
            position: sticky;
        }
    }
  }
form{
    .btn{
        margin-left: 0px;
    }
}
.input-group-prepend{
    &.error{
        .input-group-text{
            background:red;
            color:white;
        }
    }
}
.formerror{
    border:2px solid red!important;
}
.popover{
    position:absolute;
    z-index: 999;
  }
.formtitle{
    .card{
      margin:5px 0px!important;
      
    }
    .card-body{
      padding:10px!important;
      .openindi{
          float: right;
          margin-left: 10px;
      }
      .formdate{
        float: right;
        margin-right: 10px;
      }
    }
  }
.news{
    max-height: 550px;
    overflow-y:auto;
    .newsitem{
        h3{
            font-size: 18px;
            line-height: 25px;
            .icon{
                font-size:25px!important;
                vertical-align: middle
            }
            margin:0px;
        }
        border-radius:0rem!important;
        .card-header{
            font-family: 'Poppins', sans-serif;
            border-radius:0rem!important;
            padding-top:7px;
            padding-bottom:7px;
            background:$grey;
        }
    }
}

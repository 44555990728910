.note{
    position:relative;
    background: #fafafa;
    border-radius: 5px;
    border:solid 1px #f1f1f1;
    padding:18px 40px 18px 18px;
    margin-bottom: 7px;
    .date{
        position:absolute;
        right:5px;
        bottom:5px;
        font-size: 9px;
    }
    .delete{
        position:absolute;
        right:5px;
        top:0px;
        font-size: 12px;
    }
}
.newnote{
    padding:20px;
    textarea{
        resize: none;
    }
}
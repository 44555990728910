:root {
    --white: $white!important;
    --gray: $grey!important;
    --gray-dark: $dark-grey!important;
    --primary: $primary!important;
    --secondary: $secondaey!important;
    --success: $primary!important;
    --info: #17a2b8!important;
    --warning: $secondaryAction!important;
    --danger: $warning!important;
    --light: $white!important;
    --dark: $black!important;
}

.tabContainer{
    padding:0 0 10px 0;
    border-bottom:1px $light-grey solid;
    border-right:1px $light-grey solid;
    border-left:1px $light-grey solid;
}
.btn-danger{
    background:$warning;
    color:$white!important;
    border-color: $white!important;
}
.modal-lg{
    max-width: 1000px!important;
}
.xoverfow{
    overflow-x:auto;
    overflow-y: hidden;
}
.row {
    margin: 0 !important;
}
.section{
    padding-top:30px!important;
}
.container-fluid {
    padding: 0px;
}
.bg-dark {
    background: $gradient-vertical !important;
}
.bg-light {
    background:$light-grey!important;
}
.input-group{
    .form-control{
        border-color:$light-grey;
    }
    .input-group-text{
        background:$secondary;
        color:$white;
    }
}
.card {
    background-color: $white;
    border-radius: .45rem;
    margin-bottom:30px;
    .card-header{
        font-family: 'Poppins', sans-serif;
        background:$white;
        color:$dark-grey;
        font-size: 18px;
        line-height:40px;
        .btn-danger{
            background:none;
            color:$white!important;
            border-color: $white!important;
            &:hover{
                background:$warning;
            }
        }
    }
}
.btn{
    &.bt-tny{
        line-height: 0.5!important;
        border-width: 1px!important;
        font-size: 12px!important;
        padding:5px!important;
    }
}

.nav-tabs{
    .nav-link{
        font-family: 'Poppins', sans-serif;
        border-radius:7px 7px 0 0!important;
        border:none!important
    }
}

.tab-content{
    border-radius:0 0 .45rem .45rem;
    border:1px solid rgba(0,0,0,.125);
}
h1{
    line-height:40px;
}
.bucketholder{
    display:flex;
    height:100%;
    flex-direction: row;
    width:100%;
    overflow-x: scroll;
    align-items: stretch;
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;

    .bucket{
        display:flex;
        flex: 1;
        flex-direction: column;
        height: 90%;
        max-width:500px;
        min-width:300px;
        box-shadow: 2px 2px 2px $dark-grey;
        border:$dark-grey 1px solid;
        border-radius: 5px;
        margin: 10px;
        &::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none;
        scrollbar-width: none;
        .bucketTitle{
            background:$gradient;
            color:$white;
            padding:10px;
        }
        .bucketBody{
            display:inline-block;
            padding:20px;
            background:$off-white;
            color:$black;
            height:100%;
            overflow-y:scroll;
            ul{
                padding:0;
            }
            .card_object{
                padding:0;
                width:100%;
                .card{
                    width:100%;
                }
            }
        }
    }
}
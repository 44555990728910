.tabContainer{
    h3{
        font-size: 10px;
    }
    border-top-right-radius:0px;
    border-top-left-radius:0px;
}
.loztabs{
    margin-top:0px;
    margin-bottom:20px!important;
    .card{
        margin-top:0px!important;
        margin-bottom:0px!important;
        padding:0!important;
        font-size: 11px;
    }
}
.verticalTab{
    width:100%;
    padding:20 10;
    border-radius: 5px;
    background:$light-grey;
    border:solid 1px $dark-grey;
    margin-bottom: 5px;
    padding:20px;
    .vtab-header{
        font-size: 15px;
        .chev{
            float:right;
        }
    }
    .vtab-body{
        border-top:solid 1px $dark-grey;
        padding:10px 0;
        margin:20px 0;
        
    }
}
.templatePageComponents{
    .item{
        display: flex;
        flex:1;
        flex-direction:row;
        justify-content: center;
        padding:10px;
        border-radius: 5px;
        background:$secondary;
        color:$white;
        border:solid 1px $dark-grey;
        cursor: pointer;
        &:hover{
            box-shadow: 1px 1px 5px $dark-grey;
        }
        .elementimageholder{
            display:flex;
            flex:1;
            flex-direction: column;
            justify-content: center;
            width:30%;
            .elementimage{
                width:100%;
                display:inline-block;
            }
        }
        
        .elementtext{
            .title{
                font-size: 15px;
                display:block;
            }
            .desc{
                font-size: 11px;
                display:block;
            }
            .button{
                font-size: 11px;
                display:block;
                padding:5px;
                border:$light-grey;
                border-radius: 3px;
                width:fit-content;
                float: right;
                margin-top: 10px;
                color: $dark-grey;
                background:$white;
                cursor: pointer;
                &:hover{
                    background:$light-grey;
                    color:$white;
                    
                }
            }
            width:60%;
            padding:10px 5%;
            display:inline-block;
            text-align: right;
        }
    }
}

$black:#3a3a3a;
$primary:#2f037e;
$primaryAction:#9966ff;;
$secondary:#4285f4;
$secondaryAction:#00deb4;;
$tertiary:#9f57da;
$white:#fff;
$off-white:#F3F3F3;
$light-grey:#e2e2e2;
$grey:#c9c9c9;
$dark-grey:#b8b8b8;
$lighter-grey:#f1f0f0;
$background:url('/images/headerBG.jpg');
$warning:#D6381E;
$green:#00deb4;
$gradient:#2f037e;
@function makegradient($from, $to) {
    @return linear-gradient(90deg, $from 0%, $to 100%);
  }
$gradient-vertical:#2f037e;
@import 'custom', 'pipeline', 'notes', 'helpers', 'filters', 'navbar', 'sidebar', 'login', 'bootstrap-overwrites', 'buttons', 'modal', 'charts', 'forms', 'tables', 'news', 'footer', 'help', 'accordion', 'steps', 'heatmap', 'users', 'cards', 'products', 'stats', 'tabs', 'tooltips', 'chats', 'templateComponents', 'canvasComponents';
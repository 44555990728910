.chattitle{
    .card{
      margin:5px 0px!important;
      
    }
    .card-body{
      padding:10px!important;
      .openindi{
          float: right;
          margin-left: 10px;
      }
      .chatdate{
        float: right;
        margin-right: 10px;
      }
    }
  }
.chat{
    .card{
        margin:5px 0px!important;
    }
    .card-body{
        .chatitem{
            border-radius: 15px;
            padding:5px 15px!important;
            background:makegradient($secondary, $primary);
            color: $white;
            margin-bottom: 4px;
            width:70%;
            float:right;
            text-align: right;
            &.me{
                background:makegradient($green, rgba(43, 155, 131, 0.4));
                float:left;
                text-align:left;
            }
            .chatname{
                display:block;
                font-size:10px;
            }
        }
    }
}
.flgChatContainer{
  border-radius:5;
  background-color:#f2f2f2;
  padding:20px;

  .validationerror{
    font-size:10px;
    color:#bd3a3a;
    position:relative;
  }
  .chatClosed{
    height:50px;
    width:100px;
    background-color:#4caf4f;
    border-radius:25px;
    font-size:30px;
    line-height:50px;
    text-align:center;
    color:#fff;
  }
  .flgEnd{
    display:block;
    text-align:center;
    position:relative;
    background-color:#f2f2f2;
    color:#333;
    width:20px;
    height:20px;
    line-height:20px;
    font-size:12px;
    border-radius:50%;
    bottom:-10px;
    left:-10px;
    z-index:999;
    cursor:pointer;
  }
  .flgh3{
    text-align:center;
    font-size:14px;
    color:#333;
    border-bottom:1px solid #ccc;
    line-height:40px;
    height:40px;
  }
  label{
    margin-top:10px;
    line-height:11px;
    font-size:11px;
    color:#333;
  }
  .start{
    display:flex;
    flex-flow:column;
    justify-content:center;
    height:100%;
  }
  .nameinput{
    width:100%;
    padding:12px 20px;
    margin:0px;
    display:inline-block;
    border:1px solid #ccc;
    border-radius:4;
    box-sizing:border-box;
  }
  .emailinput{
    width:100%;
    padding:12px 20px;
    margin:0px;
    display:inline-block;
    border:1px solid #ccc;
    border-radius:4;
    box-sizing:border-box;
  }
  .flgMessageSend{
    margin:8px 0;
    height:100px;
  }
  .flgMessageSendLabel{
    line-height:11px;
    font-size:11px;
    color:#333;
  }
  .messagebox{
    width:100%;
    padding:12px 20px;
    margin:0px;
    display:inline-block;
    border:1px solid #ccc;
    border-radius:4;
    box-sizing:border-box;
  }
  .chatwindow{
    height:350px;
    width:100%;
    background:white;
    overflow-y:scroll;
    border:1px solid #ccc;
    border-radius:4;
    box-sizing:border-box;
    padding:5px;
  }
  .message{
    padding:5px;
    margin:10px 0;
    width:70%;
    color:#fff;
    border-radius:4;
    border:1px solid #ccc;
  }
  .me{
    background:#4caf4f;
    float:right;
  }
  .infobox{
    width:70%;
    float:right;
  }
  .info{
    background:#cfcfcf;
    float:right;
    padding:2px 7px;
    margin:10px 0;
    color:#fff;
    border-radius:8px;
    border:1px solid #ccc;
  }
  .from{
    background:rgb(152, 153, 255);
    float:left;
  }
  .payne{
    display:table-cell;
    vertical-align:bottom;
    width:100%;
    float:right;
  }
  .flgSubmitHover{
    background-color:#45a049;
  }
  .chatOpen{
    z-index:998;
    width:400px;
    min-height:380px;
  }
  .flgSubmit{
    width:100%;
    background-color:#4CAF50;
    color:white;
    padding:14px 20px;
    margin:8px 0;
    border:none;
    border-radius:4px;
    cursor:pointer;
  }
  
}
.containergroup{
  
  .card-header{
    background:$secondary;
    color:$white;
  }
  &.inactive{
    .card-header{
      border-radius: 5px!important;
    }
  }
  .groupBar{
    background: $light-grey;
    padding:5px 7px;
  }
}
.lozcards{
  margin-top:0px;
  margin-bottom:10px!important;
  .card{
      margin-top:0px!important;
      margin-bottom:0px!important;
  }
  .card-body{
    padding-top:10!important;
    padding-bottom:0!important;
    padding-left:0;
    padding-right:0;
  }
}

.item-card{
  .card-controls{
    width:100%;
    margin-bottom:3px;
    height:19px;
    span{
      line-height: 19px;
    }
  }
  .input-group{
      margin-bottom: 0px!important;
      margin-top: 0px!important;
  } 
  .text-muted{
    margin:0;
    line-height:15px;
  }
  border: 1px $light-grey solid;
  padding:5px;
  margin-bottom: 15px;
  border-radius: 5px;
}

.card-header{
  padding:7px;
}
.card-list {
  padding:10px 0 0 0;
}
.card_object {
  list-style: none;
  margin-bottom: 20px;
  .card {
    border-radius: 5px;
    .dot{
      display:inline-block;
      height:22px;
      min-width:18px;
      font-size: 12px;
      line-height: 22px;
      color:$white;
      text-align: center;
      border-radius: 9px;
      padding:0 10px;
      margin:0 0 0 3px;
      background:$secondary;
      &.warning{
        background:$dark-grey;
      }
      &.template{
        background:$tertiary;
      }
    }
    &.inactive{
      background:$lighter-grey!important;
      .list-group-item{
        background:$lighter-grey!important;
      }
      color:$grey;
      .card-header{
        color:$grey!important;
      }
      .card-footer {
        background: $dark-grey;
      }
      .card-footer {
        .barbutton {
          &.warning {
              background:$grey;
              color:$white;
              &:hover{
                background:$warning!important;
                color: $white!important;
              }
              
            }
            &.active{
              background:$warning!important;
            }
        }
      }
    }
    .card-body {
      padding: 0 10px 10px 10px;
      .buttonbar {
        border: 1px #ccc solid;
        border-radius: 3px;
        background:$white;
        .cardbutton {
          display: block;
          position: relative;
          text-align: center;
          color: #333;
          padding-top: 10px;
          padding-bottom: 10px;
          width: 100%;
          cursor: pointer;
          &:hover {
            background: $secondary;
            color: #f2f2f2;
          }
          &.on {
            background: $secondaryAction;
            color: #f2f2f2;
            cursor: pointer;
          }
        }
      }
    }
    .card-header {
      font-size: 18px;
      line-height: 34px;
      padding: 0.75rem 0.75rem;
      border-bottom: none;
      color:$black;
      
      .btn {
        border: none;
        float: right;
        height: 34px;
        width: 58px;
        padding: 4px 10px !important;
        margin: 0;
      }
    }
    .card-footer {
      padding: 10px 8px 10px 0!important;
      background: $gradient;
      justify-content: space-between;
      .barbutton {
        border-radius: 2px !important;
        padding: 6px 10px !important;
        font-size: 12px !important;
        background: rgba(255, 255, 255, 0.3);
        border:none;
        color: #fff;
        margin: 0 0px 0 7px;
        &:hover {
          background: $white!important;
          color: $primary!important;
        }
        &.active {
          background:$white;
          color: $primary;
          &:hover {
            background: $white!important;
            color: $secondary!important;
          }
        }
        &:active {
          background: $white!important;
          color: $primary;
        }
        &.inactive {
          background:$grey;
          color: $white;
        }
        &.warning {
          background:$warning;
          color:$white;
          &:hover{
            background:$white!important;
            color: $warning!important;
          }
        }
        &.danger{
          background:$warning;
          color:$white;
          cursor: not-allowed;
          &:hover{
            background:$white!important;
            color: $warning!important;
          }
        }
        &.on {
          background: #fff;
          color: $secondaryAction;
          cursor: pointer;
        }
      }
    }
  }
}
.nostuff {
  text-align: center;
  color: #ccc;
  margin: 3% 10%;
  padding: 3% 10%;
  text-shadow: 1px 1px #f2f2f2;
  h1{
    margin-bottom: 1.5rem;
    font-family: inherit;
    line-height: 1.1;
    color: #343a40;
  }
}

.popup-card{
  .card-header{
    padding:3px 7px;
  }
}
.popup-card{
  .card-header{
    padding:3px 7px;
  }
}
.subcard{
  .card-header{
    padding:2px 7px;
    font-size: 12px;
    line-height: 14px;
    background:$white;
    color:$dark-grey;
  }
}